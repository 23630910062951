var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VExpansionPanels',{staticClass:"py-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',{attrs:{"expand-icon":"fal fa-chevron-down"}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(" Новая компания ")])]),_c('VExpansionPanelContent',[_c('VForm',{ref:"form"},[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"md":"6"}},[_c('div',[_c('strong',[_vm._v("Имя")])]),_c('VTextField',{attrs:{"counter":_vm.maxNameSize},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"md":"6"}},[_c('div',[_c('strong',[_vm._v("Аккаунт")])]),_c('VSelect',{attrs:{"items":_vm.accounts,"item-text":"name","item-value":"id"},model:{value:(_vm.entity.accountId),callback:function ($$v) {_vm.$set(_vm.entity, "accountId", $$v)},expression:"entity.accountId"}})],1)],1),_c('VRow',[_c('VCol',{attrs:{"md":"4"}},[_c('VBtn',{staticClass:"mr-3",attrs:{"large":"","depressedgit":"","color":"primary","disabled":!_vm.isValidCreateForm},on:{"click":_vm.save}},[_vm._v(" Создать компанию ")]),_c('VBtn',{attrs:{"large":"","text":"","depressed":""},on:{"click":_vm.cancel}},[_vm._v(" Отмена ")])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('VRow',[_c('VCol',[_c('CompaniesTable',{attrs:{"companies":_vm.entitiesList,"loading":_vm.loading,"search":_vm.searchCompanies},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('TTTextField',{attrs:{"placeholder":"Фильтр...","append-icon":"fal fa-search"},model:{value:(_vm.searchCompanies),callback:function ($$v) {_vm.searchCompanies=$$v},expression:"searchCompanies"}})]},proxy:true},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","color":"blue","to":{ name : _vm.Names.R_ACCOUNT_V2_COMPANY_VIEW,
                       params : { companyId : item.id, accountId : item.accountId } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                       var on = ref.on;
                       var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","disabled":!item.active,"to":{ name : _vm.Names.R_ACCOUNT_SYS_COMPANY_EDIT, params : { id : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                       var on = ref.on;
                       var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){return _vm.handleActivation(item.active, item.id)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":"","color":item.active ? 'red' : 'green'}},[_vm._v(" fal "+_vm._s(item.active ? 'fal fa-lock' : 'fal fa-unlock')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.active ? 'Деактивировать' : 'Активировать'))])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                       var on = ref.on;
                       var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green","to":{ name : _vm.Names.R_ACCOUNT_SYS_COMPANY_USER_ADD, params : { companyId : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Добавить Администратора")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                       var on = ref.on;
                       var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"red","to":{ name : _vm.Names.R_ACCOUNT_SYS_COMPANY_USER_REMOVE, params : { companyId : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить Администратора")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }