<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="companies"
    :loading="loading"
    :search="search"
  >
    <template #top>
      <VRow class="ma-0">
        <VCol cols="6">
          <VToolbarTitle>Компании</VToolbarTitle>
        </VCol>
        <VCol cols="6">
          <slot name="topActions" />
        </VCol>
      </VRow>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'CompaniesTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    companies: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      headers: [
        {
          value: 'id',
          class: 'd-none',
          align: ' d-none',
        },
        {
          value: 'accountId',
          class: 'd-none',
          align: ' d-none',
        },
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Аккаунт',
          align: 'left',
          sortable: true,
          value: 'accountName',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
